import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { useCallbackWithAnalytics } from '@atlaskit/analytics-next/useCallbackWithAnalytics';
import Popup from '@atlaskit/popup';
import { AppSwitcher } from '@atlaskit/atlassian-navigation';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { AtlassianSwitcherPrefetchTrigger } from '@atlassian/switcher';

import { fg } from '@confluence/feature-gating';
import { useRouteDataRef } from '@confluence/route-manager';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { LoadableBackground } from '@confluence/loadable';
import { useSessionData } from '@confluence/session-data';
import { START_TOUCH } from '@confluence/navdex';
import { useQuickstartEligible } from '@confluence/onboarding-quickstart';

import { AppSwitcherNudgeToolTip } from './AppSwitcherNudgeTooltipComponent';

const i18n = defineMessages({
	appSwitcherTooltip: {
		id: 'app-navigation.app-switcher.tooltip',
		defaultMessage: 'Switch to...',
		description: 'Tooltip shown when mouse pointer hovers over the App Switcher button',
	},
	appSwitcherLabel: {
		id: 'app-navigation.app-switcher.label',
		defaultMessage: 'Switch to other Atlassian services',
		description: 'Label for the App Switcher button',
	},
});

const CONFLUENCE_PRODUCT = 'confluence';

const SwitcherContentLoadable = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AppSwitcherContent" */ './AppSwitcherContent'))
			.SwitcherContent,
});

export const AppSwitcherComponentImpl: FC<WithFlagsProps> = ({ flags }) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const routeDataRef = useRouteDataRef();
	const { isLicensed, cloudId, userId } = useSessionData();

	const { isJswXflowUserQSEligible } = useQuickstartEligible();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouteActions();

	useEffect(() => {
		void (async () => {
			const {
				checkJpdCrossJoinNudgeEligibility,
				showJpdCrossJoinNudge,
				triggerJiraCrossJoinNudge,
			} = await import(
				/* webpackChunkName: "loadable-confluencecross-join-nudges" */ '@confluence/cross-join-nudges'
			);

			const isJpdCrossJoinNudgesEligible = await checkJpdCrossJoinNudgeEligibility({
				cloudId,
				userId,
				createAnalyticsEvent,
			});

			isJpdCrossJoinNudgesEligible
				? await showJpdCrossJoinNudge({
						cloudId,
						userId,
						flags,
						createAnalyticsEvent,
						push,
					})
				: await triggerJiraCrossJoinNudge({
						cloudId,
						userId,
						flags,
						createAnalyticsEvent,
						push,
					});
		})();
	}, [cloudId, createAnalyticsEvent, flags, push, userId]);

	const onClick = useCallbackWithAnalytics(
		() => {
			setIsOpen(!isOpen);
		},
		{
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'appSwitcher',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START_TOUCH,
				},
			},
		},
	);

	const onClose = () => {
		setIsOpen(false);
	};

	return !isLicensed ? null : (
		<AtlassianSwitcherPrefetchTrigger cloudId={cloudId} product={CONFLUENCE_PRODUCT}>
			<Popup
				placement="bottom-start"
				rootBoundary="document"
				content={() => <SwitcherContentLoadable cloudId={cloudId} onClose={onClose} />}
				isOpen={isOpen}
				onClose={onClose}
				shouldRenderToParent
				trigger={(triggerProps) => {
					const appSwitcherTrigger = (
						<AppSwitcherNudgeToolTip>
							<AppSwitcher
								onClick={onClick}
								tooltip={
									// WARN: not importing the message from @atlassian/switcher
									// because single message from this package pulls more than 100 kB
									// of i18n JS into page loading critical path.
									<FormattedMessage {...i18n.appSwitcherTooltip} />
								}
								isSelected={isOpen}
								label={formatMessage(i18n.appSwitcherLabel)}
								{...triggerProps}
							/>
						</AppSwitcherNudgeToolTip>
					);

					return (
						<span data-testid="app-navigation-app-switcher">
							{isJswXflowUserQSEligible && fg('cc_onboarding_experience') ? (
								<SpotlightTarget name="app-switcher-spotlight">
									{appSwitcherTrigger}
								</SpotlightTarget>
							) : (
								appSwitcherTrigger
							)}
						</span>
					);
				}}
			/>
		</AtlassianSwitcherPrefetchTrigger>
	);
};

export const AppSwitcherComponent = withFlags(AppSwitcherComponentImpl);
